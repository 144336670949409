import * as React from 'react';
import { useLocation } from '@reach/router';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import { useEventListener } from '@core/hooks';
import { getElementOffset, addCssCustomProperty } from '@core/utils';
import { TableOfContentItem } from '@interface/gatsby';
import * as styles from './TableOfContents.module.scss';


type Props = {
  items: TableOfContentItem[];
  onClick?: React.MouseEventHandler;
};

function TableOfContents({ items, onClick }: Props) {
  const componentId = React.useId();
  const location = useLocation();
  const hash = location.hash !== '' ? location.hash : items[0]?.url;
  const [activeHash, setActiveHash] = React.useState<string>(hash);

  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (location.hash !== '') {
      const html = document.documentElement;
      const style = getComputedStyle(html);
      const offset = parseInt(style.scrollPaddingTop);
      const position = getElementOffset(location.hash, offset);

      timer = setTimeout(() => {
        window.scrollTo(0, position);
      }, 0);
    }

    addCssCustomProperty('--scroll-behavior', 'smooth');

    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEventListener('hashchange', () => {
    setActiveHash(window.location.hash);
  });

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <React.Fragment key={`${componentId}-${index}`}>
          <a
            href={item.url}
            className={clsx(styles.link, { [styles.link__active]: item.url === activeHash })}
            onClick={onClick}
            suppressHydrationWarning
          >
            {item.title}
          </a>
        </React.Fragment>
      ))}
    </div>
  );
}

export default React.memo(TableOfContents, ((prevProps, nextProps) => {
  return isEqual(prevProps.items, nextProps.items);
}));
